
import React, { useEffect, useState } from "react";

function PDFInfo({locations, lostCall, lostCallName, specialInstructions, formatPhoneNumber}) {
    const [isSafari, setIsSafari] = useState(false);

    useEffect(() => {
      setIsSafari(checkBrowser());
    }, []);

    const checkBrowser = () => {
        const ua = navigator.userAgent;
        return (
          ua.includes("Safari") && !ua.includes("Chrome") && !ua.includes("Chromium")
        );
      };

    const styles = {
        flexContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            margin: '0px 13px 0px 10px',
            boxSizing: 'border-box',
            minHeight: 'auto'
            
        },
        flexItem: {
            width: '20%',
            border: '3px solid #000',
            flexGrow: 1,
            padding: 10,
            minHeight: 'auto',
            boxSizing: 'border-box',
            margin: '0px -3px -3px 0px',
            textAlign: 'center',
            display: 'flex', // Make the flexItem a flex container
            flexDirection: 'column', // Stack child elements vertically
            justifyContent: 'center', // Center vertically
            alignItems: 'center' // Center horizontally        
        },
        type: {
            fontSize: '1.1em',
            fontWeight: 'bold',
            width: '100%',
            textDecoration: 'underline'
        },
        name: {
            width: '100%',
            fontSize: '0.95em',
            fontWeight: 800
        },
        address: {
            width: '100%',
            fontSize: '0.95em',
            fontWeight: 800

        }
      }
      
      return (
        <div>
            <div style={styles.flexContainer}>
            {locations.map((location, index) => (
                <div key={index} style={styles.flexItem}>
                    <div style={styles.type}>{location.type} {location.type === 'SET' ? ` - "${location.setName}"` : ''}</div>
                    <div style={styles.name}>{location.name}</div>
                    <div style={styles.address}>{location.mapData.address}</div>
                </div>
                ))}
                {isSafari ? <>
                            <div style={{...styles.flexItem, backgroundColor: 'lightgrey'}}>
                               <div style={styles.type}>SPECIAL INSTRUCTIONS</div>
                                <div style={styles.name}>{specialInstructions ? specialInstructions : 'N/A'}</div>
                            </div>
                            <div style={{...styles.flexItem, backgroundColor: 'lightgrey'}}>
                                <div style={styles.type}>LOST? CALL {lostCallName ? lostCallName : '...'}</div>
                                <div style={styles.name}>{lostCall ? formatPhoneNumber(lostCall) : 'N/A'}</div>
                            </div></>
                            : <>
                            {specialInstructions && <div style={{...styles.flexItem, backgroundColor: 'lightgrey'}}>
                                <div style={styles.type}>SPECIAL INSTRUCTIONS</div>
                                <div style={styles.name}>{specialInstructions}</div>
                            </div>}
                            {lostCall && <div style={{...styles.flexItem, backgroundColor: 'lightgrey'}}>
                                <div style={styles.type}>LOST? CALL {lostCallName ? lostCallName : '...'}</div>
                                <div style={styles.name}>{formatPhoneNumber(lostCall)}</div>
                            </div>}
                            </>
                        }
            </div>
        </div>
        );
    }

    export default PDFInfo;
