import React, { useState, useCallback, useEffect } from 'react';
import { GoogleMap, Marker, Polyline } from '@react-google-maps/api';
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../../util/auth";
import PDFTurnByTurnDirections from './PDFTurnByTurnDirections';
import PDFInfo from './PDFInfo';

const useStyles = makeStyles((theme) => ({
  modalContainer: {
      padding: 10,
      background: 'white',
      WebkitFontSmoothing: 'antialiased', // Camel-cased for React
      fontSmoothing: 'antialiased',      // Optional fallback
  },
  pdfContainer: {
      backgroundColor: 'white',
      color: 'black',
      border: '4px solid black',
  },
  flexContainerTitles: {
      display: 'flex',
      flexDirection: 'row',
      minWidth: '100%',
      paddingLeft: 10,
      paddingRight: 20,
      borderBottom: '2px solid black',
      fontSize: '1.2em'
  },
  toFrom: {
    width: '100%',
  },
  startLocation: {
    marginBottom: -20
  }
}));

const PDFMapsDirections = ({ date, set, group, dropdownOrigin, dropdownDestination, allGroupLocations, lostCall, lostCallName, specialInstructons, formatPhoneNumber, setDestinationObject, disableDefaultUI }) => {
  const auth = useAuth();
  const classes = useStyles();
  const [routePath, setRoutePath] = useState([]);
  const [steps, setSteps] = useState([]);
  const [map, setMap] = useState(null);
  const [satelliteMap, setSatelliteMap] = useState(null);
  const [satTilt, setSatTilt] = useState(0);
  const [gps, setGPS] = useState({});
  const [totalDuration, setTotalDuration] = useState(0)

  // Function to get directions and update the Polyline path
  const fetchDirections = useCallback(() => {
    const directionsService = new window.google.maps.DirectionsService();
    directionsService.route(
      {
        origin: dropdownOrigin.mapData.coords,
        destination: dropdownDestination.mapData.coords,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK && result.routes[0]) {
          // Extract the path from the directions result
          // const path = result.routes[0].overview_path.map((point) => ({
          //   lat: point.lat(),
          //   lng: point.lng(),
          // }));
          const path = result.routes[0].legs[0].steps.map((step) => {
            return step.path.map((point) => ({
              lat: point.lat(),
              lng: point.lng(),
            }));
          }).flat(); // Flatten the array of points if needed
          setRoutePath(path); // Update the polyline path state
          const totalDuration = result.routes[0].legs.reduce((total, leg) => {
            return total + leg.duration.value; // Add up the duration of each leg
          }, 0);
          setTotalDuration(totalDuration);

          // Extract step-by-step directions
          const routeSteps = result.routes[0].legs[0].steps.map((step) => ({
            instructions: step.instructions,
            maneuver: step.maneuver,
            distance: step.distance.text,
            duration: step.duration.text,
          }));
          setSteps(routeSteps);
          // Set bounds based only on the origin and destination locations
          const bounds = new window.google.maps.LatLngBounds();
          bounds.extend(result.routes[0].legs[0].start_location); // Origin
          bounds.extend(result.routes[0].legs[0].end_location);   // Destination
          map?.fitBounds(bounds);
          satelliteMap?.setZoom(18);
        } else {
          setSteps([]);
          console.error('Error fetching directions:', status);
        }
      }
    );
  }, [dropdownDestination.mapData.coords, dropdownOrigin.mapData.coords, map, satelliteMap]);

  const defaultMapLabelOptions = [
    {
      featureType: "all",
      stylers: [{ visibility: "off" }], 
    },
    {
      featureType: "landscape",
      stylers: [{ visibility: "on" }], 
    },
    {
      featureType: "water",
      stylers: [{ visibility: "on" }], 
    },
    {
      featureType: "road",
      stylers: [{ visibility: "on" }], 
    },
    {
      featureType: "transit",
      stylers: [{ visibility: "on" }], 
    },
    {
      featureType: "administrative",
      stylers: [{ visibility: "on" }], 
    },
    {
      featureType: "administrative.land_parcel",
      stylers: [{ visibility: "off" }], 
    },
    {
      featureType: "poi.government",
      stylers: [{ visibility: "on" }], 
    },
    {
      featureType: "poi.park",
      stylers: [{ visibility: "on" }],
    },
    {
      featureType: "poi.school",
      stylers: [{ visibility: "on" }]
    },
    {
      featureType: "poi.attraction",
      stylers: [{ visibility: "on" }]
    }
    ]

  const handleMapLoad = useCallback((mapInstance) => {
    setMap(mapInstance);
  }, []);

  const handleSatelliteMapLoad = useCallback((mapInstance) => {
    setSatelliteMap(mapInstance);
    mapInstance.setMapTypeId('hybrid');
    mapInstance.addListener('tilt_changed', (event) => {
        var tilt = mapInstance.getTilt();
        setSatTilt(tilt);
    });
  }, []);

  const handleOnDragEnd = (event) => {
    const newCoords = {lat: event.latLng.lat(), lng: event.latLng.lng()};
    const jsonCoords = event.latLng.toJSON();
    const geocoder = new window.google.maps.Geocoder();  
    geocoder.geocode({ location: jsonCoords }, (results, status) => {
      if (status === 'OK') {
        const newAddress = results[0].formatted_address;
        // update dest with new coords and addy from onDragEnd
        setDestinationObject(null, {
          ...dropdownDestination,
          mapData: {
            address: newAddress,
            coords: newCoords
          },
        });
      } else {
        console.error('Geocoding failed to get address from drag event:', status);
        // no new addy so just update coords
        setDestinationObject(null, {
          ...dropdownDestination,
          mapData: {
            ...dropdownDestination.mapData, 
              coords: newCoords
          },
        });
      }
    });
  }

  // Fetch directions whenever destination changes
  useEffect(() => {
    fetchDirections();
    // Function to convert latitude and longitude to GPS coordinates in DMS format
    // Helper function to convert decimal to DMS format
    const toDMS = (coordinate) => {
      const absolute = Math.abs(coordinate);
      const degrees = Math.floor(absolute);
      const minutesNotTruncated = (absolute - degrees) * 60;
      const minutes = Math.floor(minutesNotTruncated);
      const seconds = ((minutesNotTruncated - minutes) * 60).toFixed(2);
      return `${degrees}° ${minutes}' ${seconds}"`;
    };
    const latDMS = `${toDMS(dropdownDestination.mapData.coords.lat)} ${dropdownDestination.mapData.coords.lat >= 0 ? 'N' : 'S'}`;
    const lngDMS = `${toDMS(dropdownDestination.mapData.coords.lng)} ${dropdownDestination.mapData.coords.lng >= 0 ? 'E' : 'W'}`;
    setGPS({ lat: latDMS, lng: lngDMS });
  }, [dropdownDestination.mapData.coords.lat, dropdownDestination.mapData.coords.lng, fetchDirections]);

  return (
    <div>
      <div className={classes.modalContainer}>
        <div className={classes.pdfContainer}>
        <div className={classes.flexContainerTitles}>
          <div className={classes.toFrom}>
          <h4 className={classes.startLocation}>{new Date(date).toLocaleDateString('en-US', {weekday: 'long',year: 'numeric',month: 'long',day: 'numeric'})} | {group} {set ? "| " + set : "" }</h4>
          <h4 className={classes.startLocation}>FROM: {dropdownOrigin?.type} - {dropdownOrigin?.name} {dropdownOrigin?.mapData?.address}</h4>
          <h4 className={classes.startLocation}>TO: {dropdownDestination?.type} - {dropdownDestination?.name} {dropdownDestination?.mapData?.address}</h4>
          <h4 >GPS COORDINATES: {gps?.lat} and {gps?.lng} </h4>

          </div>
          <div style={{minWidth: '30%', textAlign: 'right', padding: '5px' }}>
            <h1 style={{fontSize: '2.2em'}}>{auth.user.productionName}</h1>
            {/* <h4 style={{ marginTop: '0', marginRight: '5px'}}>powered by showNAV</h4> */}
          </div>

        </div> 
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', backgroundColor: 'white', padding: 10}}>
       <GoogleMap
          onLoad={handleMapLoad}
          mapTypeId='hybrid'
          center={dropdownDestination.mapData.coords}
          zoom={12}
          mapContainerStyle={{flex: 1, height: '450px', width: '50%' }}
          options={{disableDefaultUI: disableDefaultUI, streetViewControl: false, styles: defaultMapLabelOptions}}
        >

          {/* Custom Polyline for the route */}
          <Polyline path={routePath} options={{ strokeColor: '#007BFF', strokeOpacity: 0.7, strokeWeight: 9, geodesic: true }} />

          {/* Origin Marker */}
          <Marker
            key={'start'}
            position={dropdownOrigin.mapData.coords}
            label={{text:`A`,color:'#fff', fontWeight: 'bold', top: 20}}
          />

          {/* Draggable Destination Marker */}
          <Marker
            key={'finish'}
            position={dropdownDestination.mapData.coords}
            draggable={true}
            label={{text:`B`,color:'#fff', fontWeight: 'bold', top: 20}}
            onDragEnd={(event) => {
              handleOnDragEnd(event)
            }}
          />
        </GoogleMap>

        <GoogleMap
          onLoad={handleSatelliteMapLoad}
          center={dropdownDestination.mapData.coords}
          zoom={18}
          mapContainerStyle={{flex: 1, height: '450px', backgroundColor: '#f0f0f0', width: '50%'}}
          options={{disableDefaultUI: disableDefaultUI, tilt: satTilt, streetViewControl: false, styles: defaultMapLabelOptions}}
        >

          {/* Custom Polyline for the route */}
          <Polyline path={routePath} options={{ strokeColor: '#007BFF', strokeOpacity: 0.7, strokeWeight: 9, geodesic: true }} />

          {/* Origin Marker */}
          <Marker
            key={'start'}
            position={dropdownOrigin.mapData.coords}
            label={{text:`A`,color:'#fff', fontWeight: 'bold', top: 20}}
          />

          {/* Draggable Destination Marker */}
          <Marker
            key={'finish'}
            // zIndex={999}
            position={dropdownDestination.mapData.coords}
            draggable={true}
            label={{text:`B`,color:'#fff', fontWeight: 'bold', top: 20}}
            onDragEnd={(event) => {
              handleOnDragEnd(event)
            }}
          />
        </GoogleMap>
        </div>
        <PDFInfo locations={allGroupLocations} lostCall={lostCall} lostCallName={lostCallName} specialInstructions={specialInstructons} formatPhoneNumber={formatPhoneNumber}/>
        <PDFTurnByTurnDirections steps={steps} fromFull={dropdownOrigin} toFull={dropdownDestination} totalDuration={totalDuration}  />
        </div>
      </div>
      </div>
  );
};

export default PDFMapsDirections;